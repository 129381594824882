<template>
    <section class="provide-section" id="provide-section">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-sm-8 col-xs-12">
                    <div class="underTitle">What we provide</div>
                    <div class="title-h2 mb-56">Freefone is more than just a dialer APP</div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 hide-mobile">
                    <div class="d-flex justify-content-end flick-block">
                        <div class="mr-32 hide-tablet">
                            <img :src="imgLeftArrow" alt="" @click="prev" />
                        </div>
                        <div class="hide-tablet">
                            <img :src="imgRightArrow" alt="" @click="next" />
                        </div>
                        <div class="mr-32 show-tablet-only">
                            <img :src="imgLeftArrow" alt="" @click="prev2" />
                        </div>
                        <div class="show-tablet-only">
                            <img :src="imgRightArrow" alt="" @click="next2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="hide-desktop-only">
                <div class="d-flex justify-content-center flick-block hide-tablet">
                    <div class="mr-32">
                        <img :src="imgLeftArrow" alt="" @click="prev2" />
                    </div>
                    <div>
                        <img :src="imgRightArrow" alt="" @click="next2" />
                    </div>
                </div>
                <div>
                    <Flicking
                        ref="flickity2"
                        :options="{
                            moveType: 'strict',
                            gap: '16px',
                            circular: true,
                            prevNextButtons: false,
                            pageDots: false,
                        }"
                    >
                        <div key="slide11" class="panel">
                            <div>
                                <div class="mr-24 mr-m-0 text-center">
                                    <img :src="provide01" alt="" />
                                </div>
                                <div>
                                    <div class="provide-section-undertitle">Feature One</div>
                                    <div class="provide-section-title">SMS & MMS</div>
                                    <div>
                                        <p>
                                            Do you ever feel like giving out your number is too risky? Do you need a
                                            second number to help you keep the various aspects of your life separate?
                                            Well, that’s what a Freefone phone is for.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key="slide12" class="panel">
                            <div>
                                <div class="mr-24 mr-m-0 text-center">
                                    <img :src="provide02" alt="" />
                                </div>
                                <div>
                                    <div class="provide-section-undertitle">Feature Two</div>
                                    <div class="provide-section-title">CALL BLOCKING</div>
                                    <div>
                                        <p>
                                            Stop telemarketers, auto-dialers and other unwanted calls with our call
                                            blocking service. Define numbers to block or entire area codes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key="slide13" class="panel">
                            <div class="w-50 w-m-100 flex-grow-1 mr-56">
                                <div class="mr-24 mr-m-0 text-center">
                                    <img :src="provide03" alt="" />
                                </div>
                                <div>
                                    <div class="provide-section-undertitle">Feature Three</div>
                                    <div class="provide-section-title">CALL SCREENING</div>
                                    <div>
                                        <p>
                                            Screen your incoming calls by automatically answering with a pre-recorded
                                            message. Accept, decline, or send caller to voicemail.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key="slide14" class="panel">
                            <div class="w-50 w-m-100 flex-grow-1">
                                <div class="mr-24 mr-m-0 text-center">
                                    <img :src="provide04" alt="" />
                                </div>
                                <div>
                                    <div class="provide-section-undertitle">Feature Four</div>
                                    <div class="provide-section-title">MISS CALL NOTIFICATION</div>
                                    <div>
                                        <p>
                                            All you mis calls will be notified to you via email. You can click to call
                                            back the caller at a later time when you are free.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key="slide15" class="panel">
                            <div class="w-50 w-m-100 flex-grow-1 mr-56">
                                <div class="mr-24 mr-m-0 text-center">
                                    <img :src="provide05" alt="" />
                                </div>
                                <div>
                                    <div class="provide-section-undertitle">Feature Five</div>
                                    <div class="provide-section-title">VM TRANSCRIPTIONS</div>
                                    <div>
                                        <p>
                                            All of your voicemails will be transcribed into written format and emailed
                                            to you. Check your messages discretely via your email while on the go.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key="slide16" class="panel">
                            <div class="w-50 w-m-100 flex-grow-1">
                                <div class="mr-24 mr-m-0 text-center">
                                    <img :src="provide06" alt="" />
                                </div>
                                <div>
                                    <div class="provide-section-undertitle">Feature Six</div>
                                    <div class="provide-section-title">VOICEMAIL RECORDINGS</div>
                                    <div>
                                        <p>
                                            Voicemails you receive will be recorded and saved for you to listen at a
                                            later date. You will be notified through the app when you receive a new one.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key="slide17" class="panel">
                            <div class="w-50 w-m-100 flex-grow-1 mr-56">
                                <div class="mr-24 mr-m-0 text-center">
                                    <img :src="provide07" alt="" />
                                </div>
                                <div>
                                    <div class="provide-section-undertitle">Feature Seven</div>
                                    <div class="provide-section-title">CALL FORWARDING</div>
                                    <div>
                                        <p>
                                            Simply forward your calls to another phone number or have a list of numbers
                                            called in sequence or all at the same time so you don't miss a single call.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key="slide18" class="panel">
                            <div class="w-50 w-m-100 flex-grow-1">
                                <div class="mr-24 mr-m-0 text-center">
                                    <img :src="provide08" alt="" />
                                </div>
                                <div>
                                    <div class="provide-section-undertitle">Feature Eight</div>
                                    <div class="provide-section-title">CALL TAGGING</div>
                                    <div>
                                        <p>
                                            Choose which calls to take and which ones to handle later with our Caller
                                            Tagging feature. Prioritize your time and return calls at a later time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Flicking>
                </div>
            </div>
            <div class="hide-mobile hide-tablet">
                <Flicking
                    ref="flickity"
                    :options="{
                        moveType: 'strict',
                        gap: '16px',
                        circular: true,
                        prevNextButtons: false,
                        pageDots: false,
                    }"
                >
                    <div key="slide1" class="panel">
                        <div class="d-flex">
                            <div class="d-flex w-50 w-m-100 flex-grow-1 mr-56">
                                <div class="d-flex">
                                    <div>
                                        <img :src="provide01" alt="" />
                                    </div>
                                    <div class="ml-57">
                                        <div class="provide-section-undertitle">Feature One</div>
                                        <div class="provide-section-title">SMS & MMS</div>
                                        <div>
                                            <p>
                                                Do you ever feel like giving out your number is too risky? Do you need a
                                                second number to help you keep the various aspects of your life
                                                separate? Well, that’s what a Freefone phone is for.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex w-50 w-m-100 flex-grow-1">
                                <div class="d-flex">
                                    <div>
                                        <img :src="provide02" alt="" />
                                    </div>
                                    <div class="ml-57">
                                        <div class="provide-section-undertitle">Feature Two</div>
                                        <div class="provide-section-title">CALL BLOCKING</div>
                                        <div>
                                            <p>
                                                Stop telemarketers, auto-dialers and other unwanted calls with our call
                                                blocking service. Define numbers to block or entire area codes.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div key="slide2" class="panel">
                        <div class="d-flex">
                            <div class="d-flex w-50 w-m-100 flex-grow-1 mr-56">
                                <div>
                                    <img :src="provide03" alt="" />
                                </div>
                                <div class="ml-57">
                                    <div class="provide-section-undertitle">Feature Three</div>
                                    <div class="provide-section-title">CALL SCREENING</div>
                                    <div>
                                        <p>
                                            Screen your incoming calls by automatically answering with a pre-recorded
                                            message. Accept, decline, or send caller to voicemail.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex w-50 w-m-100 flex-grow-1">
                                <div>
                                    <img :src="provide04" alt="" />
                                </div>
                                <div class="ml-57">
                                    <div class="provide-section-undertitle">Feature Four</div>
                                    <div class="provide-section-title">MISS CALL NOTIFICATION</div>
                                    <div>
                                        <p>
                                            All you mis calls will be notified to you via email. You can click to call
                                            back the caller at a later time when you are free.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div key="slide3" class="panel">
                        <div class="d-flex">
                            <div class="d-flex w-50 w-m-100 flex-grow-1 mr-56">
                                <div>
                                    <img :src="provide05" alt="" />
                                </div>
                                <div class="ml-57">
                                    <div class="provide-section-undertitle">Feature Five</div>
                                    <div class="provide-section-title">VM TRANSCRIPTIONS</div>
                                    <div>
                                        <p>
                                            All of your voicemails will be transcribed into written format and emailed
                                            to you. Check your messages discretely via your email while on the go.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex w-50 w-m-100 flex-grow-1">
                                <div>
                                    <img :src="provide06" alt="" />
                                </div>
                                <div class="ml-57">
                                    <div class="provide-section-undertitle">Feature Six</div>
                                    <div class="provide-section-title">VOICEMAIL RECORDINGS</div>
                                    <div>
                                        <p>
                                            Voicemails you receive will be recorded and saved for you to listen at a
                                            later date. You will be notified through the app when you receive a new one.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div key="slide4" class="panel">
                        <div class="d-flex">
                            <div class="d-flex w-50 w-m-100 flex-grow-1 mr-56">
                                <div>
                                    <img :src="provide07" alt="" />
                                </div>
                                <div class="ml-57">
                                    <div class="provide-section-undertitle">Feature Seven</div>
                                    <div class="provide-section-title">CALL FORWARDING</div>
                                    <div>
                                        <p>
                                            Simply forward your calls to another phone number or have a list of numbers
                                            called in sequence or all at the same time so you don't miss a single call.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex w-50 w-m-100 flex-grow-1">
                                <div>
                                    <img :src="provide08" alt="" />
                                </div>
                                <div class="ml-57">
                                    <div class="provide-section-undertitle">Feature Eight</div>
                                    <div class="provide-section-title">CALL TAGGING</div>
                                    <div>
                                        <p>
                                            Choose which calls to take and which ones to handle later with our Caller
                                            Tagging feature. Prioritize your time and return calls at a later time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Flicking>
            </div>
        </div>
    </section>
</template>

<script>
import Flicking from '@egjs/vue3-flicking'

export default {
    components: {
        Flicking,
    },
    methods: {
        prev() {
            this.$refs.flickity.prev()
        },
        next() {
            this.$refs.flickity.next()
        },
        prev2() {
            this.$refs.flickity2.prev()
        },
        next2() {
            this.$refs.flickity2.next()
        },
    },
    data() {
        return {
            provide01: require('./../../../assets/images/components/Sections/Provide-01.svg'),
            provide02: require('./../../../assets/images/components/Sections/Provide-02.svg'),

            provide03: require('./../../../assets/images/components/Sections/Provide-03.svg'),
            provide04: require('./../../../assets/images/components/Sections/Provide-04.svg'),

            provide05: require('./../../../assets/images/components/Sections/Provide-05.svg'),
            provide06: require('./../../../assets/images/components/Sections/Provide-06.svg'),

            provide07: require('./../../../assets/images/components/Sections/Provide-07.svg'),
            provide08: require('./../../../assets/images/components/Sections/Provide-08.svg'),

            imgLeftArrow: require('./../../../assets/images/components/Sections/Arrow-left-slider.svg'),
            imgRightArrow: require('./../../../assets/images/components/Sections/Arrow-right-slider.svg'),
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/07_WeProvideSection.css';
</style>
