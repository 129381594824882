<template>
    <header>
        <Navbar />
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <router-link to="/">
                        <img :src="logo" alt="" />
                    </router-link>
                </div>
                <div class="col-md-6">
                    <ul class="header-list">
                        <li>
                            <a href="#protectIdentity-section">Why Us</a>
                        </li>
                        <li>
                            <a href="#compare-section">Pricing</a>
                        </li>
                        <li>
                            <a href="#tryforfree">Virtual Phone System</a>
                        </li>
                        <li class="hidden-custom-menu-1200">
                            <a href="#faq">FAQ</a>
                        </li>
                        <li class="hidden-custom-menu-1024 hidden-custom-menu-1400">
                            <a href="#provide-section">Our Service</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 hide-mobile hide-tablet text-m-center">
                    <a class="btn-small" href="https://portal.freefone.app"> Get Started </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Navbar from '../helpers/Navbar.vue'

export default {
    components: {
        Navbar,
    },
    data() {
        return {
            logo: require('./../../assets/images/layouts/Header/logo.svg'),
        }
    },
    methods: {
        openSlide(name) {
            let slide = this.$refs[`${name}`]
            let top = window.scrollY + slide.getBoundingClientRect().y
            window.scrollTo(0, top)
        },
    },
}
</script>

<style>
@import './../../assets/styles/components/layouts/header.css';
</style>
