<template>
    <section class="section" id="faq">
        <div class="container">
            <div class="underTitle">Popular Questions</div>
            <div class="title-h2 mb-56">Frequently Asked Questions</div>
            <div class="row">
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                    <Accordion
                        :accordionNumberCurrent="this.isActiveAccordion"
                        @mouseover="setActiveAccordionId('1')"
                        accordionNumber="1"
                        textQuestion="Is Freefone completely anonymous?"
                        textDescription="Yes, we don’t need your personal information to register an account and obtain a phone number."
                    />
                    <Accordion
                        :accordionNumberCurrent="this.isActiveAccordion"
                        @mouseover="setActiveAccordionId('2')"
                        accordionNumber="2"
                        textQuestion="Can I user my existing number in Freefone?"
                        textDescription="Yes, you can email to support@freefone.app and we will help you to port your number to us.  Only paid user can portal their numbers to freefone."
                    />
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                    <Accordion
                        :accordionNumberCurrent="this.isActiveAccordion"
                        @mouseover="setActiveAccordionId('3')"
                        accordionNumber="3"
                        textQuestion="What countries do you have numbers?"
                        textDescription="Freefone has numbers for United States and Canada."
                    />
                    <Accordion
                        :accordionNumberCurrent="this.isActiveAccordion"
                        @mouseover="setActiveAccordionId('4')"
                        accordionNumber="4"
                        textQuestion="How do you support your service if you are completely free?"
                        textDescription="Freefone is financially supported by our advertisers and sponsors. "
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Accordion from '../../helpers/Accordion.vue'

export default {
    components: {
        Accordion,
    },
    methods: {
        setActiveTabId(number) {
            this.isActiveTab = number
        },
        setActiveAccordionId(number) {
            this.isActiveAccordion = number
        },
    },
    data() {
        return {
            isActiveTab: 1,
            isActiveAccordion: 0,
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/09_FAQSection.css';
</style>
