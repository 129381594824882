<template>
    <section ref="why-us">
        <div class="container freeone-container bg-gray">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 pr-59 pr-m-0">
                    <div class="underTitle">Who are we</div>
                    <div class="title-h2 mb-32">Freefone is here to protect your identity from stolen or misuse.</div>
                    <div class="mb-32 text-t-center text-m-center">
                        <img :src="imgage" class="mb-27 img-fluid hide-desktop-only" alt="" />
                        <p>
                            Freefone is a second phone number app for calling and texting. With Freefone, you can
                            protect yourself from spam or junk calls, separate different types of contacts by phone
                            number, and switch phone numbers. We know how important privacy seriously, but it should be
                            easy as well. With the Freefone app, there are no contracts, no connection to your personal
                            number, and no annoying ads. We understand how anonying it is to get spammed on your phone.
                            Just let all the spammers to text and call your freefone numbers and let us to block them
                            for you.
                        </p>
                    </div>
                    <div class="text-t-center text-m-center">
                        <a class="btn-black" href="https://portal.freefone.app"> Get Started </a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 text-center hide-tablet hide-mobile">
                    <img :src="imgage" class="img-fluid" alt="" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            imgage: require('./../../../assets/images/components/Sections/FreeoneProtect.svg'),
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/04_FreeoneProtectSection.css';
</style>
