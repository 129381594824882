<template>
    <section class="section section-international-country">
        <div class="container">
            <div>
                <div class="section-title">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="underTitle">International</div>
                            <div class="title-h2 mb-56">Unlimited International to 80+ Destinations</div>
                        </div>
                    </div>
                </div>
                <div class="text-m-center text-t-center">
                    <div class="alphabetSwitch mb-32">
                        <span
                            :class="[this.currentCountryLetter == 'A' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('A')"
                            >A</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'B' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('B')"
                            >B</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'C' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('C')"
                            >C</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'D' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('D')"
                            >D</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'E' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('E')"
                            >E</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'F' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('F')"
                            >F</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'G' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('G')"
                            >G</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'I' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('I')"
                            >I</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'J' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('J')"
                            >J</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'K' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('K')"
                            >K</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'L' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('L')"
                            >L</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'M' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('M')"
                            >M</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'N' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('N')"
                            >N</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'O' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('O')"
                            >O</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'P' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('P')"
                            >P</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'Q' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('Q')"
                            >Q</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'R' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('R')"
                            >R</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'S' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('S')"
                            >S</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'T' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('T')"
                            >T</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'U' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('U')"
                            >U</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'V' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('V')"
                            >V</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'W' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('W')"
                            >W</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'X' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('X')"
                            >X</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'Y' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('Y')"
                            >Y</span
                        >
                        <span
                            :class="[this.currentCountryLetter == 'Z' ? 'alphabetSwitchActive' : null]"
                            v-on:click="() => switchCountry('Z')"
                            >Z</span
                        >
                    </div>
                </div>
                <div :class="[this.currentCountryLetter == 'A' ? '' : 'd-none']">
                    <div class="table-responsive">
                        <table class="section-international-country-table mt-36">
                            <tr class="section-international-country-main-table-row">
                                <td>Country</td>
                                <td>Call Rate</td>
                                <td>SMS Rate</td>
                            </tr>
                            <tr class="section-international-country-table-row">
                                <td>Albania</td>
                                <td>10¢ /minute</td>
                                <td>5¢ /sms</td>
                            </tr>
                            <tr class="section-international-country-table-row">
                                <td>Algeria</td>
                                <td>10¢ /minute</td>
                                <td>5¢ /sms</td>
                            </tr>
                            <tr class="section-international-country-table-row">
                                <td>American Samoa</td>
                                <td>10¢ /minute</td>
                                <td>5¢ /sms</td>
                            </tr>
                            <tr class="section-international-country-table-row">
                                <td>Andorra</td>
                                <td>10¢ /minute</td>
                                <td>5¢ /sms</td>
                            </tr>
                            <tr class="section-international-country-table-row">
                                <td>Angola</td>
                                <td>10¢ /minute</td>
                                <td>5¢ /sms</td>
                            </tr>
                            <tr class="section-international-country-table-row">
                                <td>Anguilla</td>
                                <td>10¢ /minute</td>
                                <td>5¢ /sms</td>
                            </tr>
                            <tr class="section-international-country-table-row">
                                <td>Antigua</td>
                                <td>10¢ /minute</td>
                                <td>5¢ /sms</td>
                            </tr>
                            <tr class="section-international-country-table-row">
                                <td>Argentina</td>
                                <td>10¢ /minute</td>
                                <td>5¢ /sms</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    methods: {
        switchCountry(countryValue) {
            this.currentCountryLetter = countryValue
        },
    },
    data() {
        return {
            currentCountryLetter: 'A',
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/08_CountryListSection.css';
</style>
