<template>
    <section id="protectIdentity-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 text-center pr-59 pr-m-5">
                    <img :src="imgage" alt="" class="img-fluid hide-mobile hide-tablet" />
                </div>
                <div class="col-lg-6 col-md-12 pl-59 pl-m-5">
                    <div class="underTitle mt-60">Why use Freefone</div>
                    <div class="title-h2 mb-32">Protect your identity from Big Data and AI.</div>
                    <img :src="imgage" alt="" class="img-fluid show-only-mobile mb-32" />
                    <img :src="imgage" alt="" class="img-fluid show-tablet-only" />
                    <div class="mb-32 text-m-center">
                        <div class="paragpraph gray-color text-t-center">
                            Every registration you submit online, every blog and twits you post, become part of the Big
                            Data and is consistently farmed by AI. Sometimes, it comes back as spam call/text and
                            sometimes, it remains silent, but it is there. With Freefone, you privacy is respected.
                        </div>
                    </div>
                    <div class="d-flex mb-32">
                        <div class="flex-grow-1">
                            <ul class="protect-list">
                                <li>Identity Threats</li>
                                <li>Security Managment</li>
                                <li>SIEM Threat Detction.</li>
                            </ul>
                        </div>
                        <div class="flex-grow-1">
                            <ul class="protect-list">
                                <li>Identitying Threats</li>
                                <li>Server Security</li>
                            </ul>
                        </div>
                    </div>
                    <div class="text-t-center text-m-center">
                        <a class="btn-indigo" href="https://portal.freefone.app"> Get Started </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            imgage: require('./../../../assets/images/components/Sections/Protect.svg'),
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/03_ProtectIdentityection.css';
</style>
