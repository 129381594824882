<template>
    <section class="pt-170 pt-m-149 mt-80">
        <div class="container">
            <div class="row">
                <div class="title-h1 mb-12">User Agreement</div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    },
}
</script>

<style>
@import './../../assets/styles/components/pages/userAgreementPage.css';
</style>
