<template>
    <section class="pt-170 pt-m-149 mt-80">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                    <div class="title-h1 mb-12 mb-m-32">Privacy and Security <span>for your online presence</span></div>
                    <div class="text-t-center text-m-center mx-w-378">
                        <p class="gray-color mb-m-32 mb-24 font-popins">
                            Freefone is the best app to protect your identity and give you peace of mind when browsing
                            the Internet.
                        </p>
                    </div>
                    <div class="d-flex mb-24 jcc-m">
                        <div class="mr-16 text-m-center">
                            <a class="btn-indigo" href="https://portal.freefone.app"> Get Started </a>
                        </div>
                        <div>
                            <a href="#contactUs" class="btn-ghost">Contact Us</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12 text-center position-relative">
                    <img :src="logo" alt="" class="main-image" />
                    <div class="bg-main-mobile"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            logo: require('./../../../assets/images/components/Sections/Main.svg'),
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/01_MainSection.css';
</style>
