import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from './components/pages/HomePage'
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage'
import UserAgreementPage from './components/pages/UserAgreementPage'

const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/userAgreement',
        component: UserAgreementPage,
    },
    {
        path: '/privacy',
        component: PrivacyPolicyPage,
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router
