<template>
    <section class="features-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div class="features-section-card">
                        <div>
                            <img class="features-image img-fluid" :src="img01" alt="" />
                        </div>
                        <div class="features-title">One phone, multiple numbers</div>
                        <div class="gray-color">
                            Freefone gives you another phone number, so you can separate family and friends from spam.
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div class="features-section-card">
                        <div>
                            <img class="features-image" :src="img02" alt="" />
                        </div>
                        <div class="features-title">Communicate safely</div>
                        <div class="gray-color">
                            Always feel safe answering the phone with spam blocking, ensuring only your chosen contacts
                            get through.
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div class="features-section-card">
                        <div>
                            <img class="features-image" :src="img03" alt="" />
                        </div>
                        <div class="features-title">Enjoy anonymity</div>
                        <div class="gray-color">
                            Your Freefone numbers can’t be traced to your primary number, while deleting and switching
                            numbers gives you control when you need it.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            img01: require('./../../../assets/images/components/Sections/Features-01.svg'),
            img02: require('./../../../assets/images/components/Sections/Features-02.svg'),
            img03: require('./../../../assets/images/components/Sections/Features-03.svg'),
        }
    },
}
</script>

<style>
@import './../../../assets/styles/components/sections/HomePage/02_FeaturesSection.css';
</style>
