<template>
    <img :src="HamburgerLogo" alt="" class="navbar-icon-hamburger" v-on:click="showNavbar = !showNavbar" />
    <div class="navbar-block" v-show="!showNavbar">
        <div>
            <img class="navbar-block--image" :src="logo" alt="" />
        </div>
        <div class="navbar-block-closeIcon">
            <img :src="iconClose" alt="" v-on:click="showNavbar = !showNavbar" />
        </div>
        <div class="text-center mt-60">
            <ul class="navbar-block-menu">
                <li>
                    <a
                        v-on:click="showNavbar = !showNavbar"
                        href="#protectIdentity-section"
                        class="navbar-block-menu-link"
                    >
                        Why us
                    </a>
                </li>
                <li>
                    <a v-on:click="showNavbar = !showNavbar" href="#compare-section" class="navbar-block-menu-link">
                        Pricing
                    </a>
                </li>
                <li>
                    <a
                        v-on:click="showNavbar = !showNavbar"
                        href="#protectIdentity-section"
                        class="navbar-block-menu-link"
                    >
                        Virtual Phone System
                    </a>
                </li>
                <li>
                    <a v-on:click="showNavbar = !showNavbar" href="#faq" class="navbar-block-menu-link"> FAQ </a>
                </li>
                <li>
                    <a v-on:click="showNavbar = !showNavbar" href="#provide-section" class="navbar-block-menu-link">
                        Our Service
                    </a>
                </li>
            </ul>
        </div>
        <div class="position-relative">
            <div class="text-center navbar-btn-block">
                <router-link to="https://portal.freefone.app" v-on:click="showNavbar = !showNavbar" class="btn-indigo">
                    Get Started
                </router-link>
            </div>
        </div>
    </div>
    <div v-on:click="showNavbar = !showNavbar" class="position-absolute black-bg" v-show="!showNavbar"></div>
</template>

<script>
export default {
    data() {
        return {
            showNavbar: true,
            iconClose: require('../../assets/images/components/Navbar/Close-icon.svg'),
            HamburgerLogo: require('./../../assets/images/components/Navbar/Hamburger-Black.svg'),
            logo: require('./../../assets/images/layouts/Header/logo.svg'),
        }
    },
}
</script>

<style>
@import '../../assets/styles/components/helpers/Navbar.css';
</style>
